import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../../constants/routes";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../Firebase/config";
import { Routes } from "../../constants";
import { injectModels } from "../../redux/injectModels";

const Dashboard = (props) => {
  const [balance, setBalance] = useState("");
  const [docData, setdocData] = useState({});
  const [verifiedData, setVerifiedData] = useState({});
  const [BlockedMerchant, setBlockedMerchants] = useState({});

  const getData = async () => {
    const q = query(
      collection(db, "Merchants"),
      where("isKycComplete", "==", true)
    );
    props.application.setLoading(true);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    props.application.setLoading(false);
    setdocData(arr);
  };
  const getVerifiedData = async () => {
    const q = query(
      collection(db, "Merchants"),
      where("isProfileVerified", "==", true)
    );
    props.application.setLoading(true);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    props.application.setLoading(false);
    setVerifiedData(arr);
  };

  const getBlockedData = async () => {
    const q = query(
      collection(db, "Merchants"),
      where("isProfileBlocked", "==", true)
    );
    props.application.setLoading(true);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    props.application.setLoading(false);
    setBlockedMerchants(arr);
  };

  const getContractAddress = async () => {
    return "0x14D231D3b8faB41091848DD4342E7c603C571acC";
  };

  const getBnbBalance = async () => { 
    const address = await getContractAddress();
    console.log(address)
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.admin.getMerchantBnbBalance(address);

        props.application.setLoading(false);
        setBalance(transaction);
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    getBnbBalance();
    getData();
    getVerifiedData();
    getBlockedData();
    
  }, []);

  return (
    <React.Fragment>
      <section className="content">
        <div className="row">
          {/* <div className='col-lg-12'>
        <PaymentProcessor paymentMethod={"Credit Card"}/>
      </div> */}
          <div className="col-lg-12">{/* <TheDotPay />  */}</div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-aqua ">
              <div className="inner">
                <p> Merchants </p>
                <h3>{docData.length}</h3>
              </div>
              <div className="icon">
                <i className="ion ion-person"></i>
              </div>
              <NavLink to={routes.MERCHANT} className="small-box-footer">
                <i className="fa fa-arrow-circle-right"></i>{" "}
                <span>View Details</span>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-green">
              <div className="inner">
                <p>verified Merchants </p>
                <h3>{verifiedData.length}</h3>
              </div>
              <NavLink to={routes.VERIFYMERCHANTS} className="small-box-footer">
                <i className="fa fa-arrow-circle-right"></i>{" "}
                <span>View Details</span>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-red">
              <div className="inner">
                <p>Block Merchants</p>
                <h3>{BlockedMerchant.length}</h3>
              </div>
              <NavLink to={Routes.BLOCKMERCHANTS} className="small-box-footer">
                <i className="fa fa-arrow-circle-right"></i>{" "}
                <span>View Details</span>
              </NavLink>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="small-box bg-yellow">
              <div className="inner">
                <p>Total Collectibles</p>
                <h3>{balance}</h3>
              </div>
              <NavLink to={Routes.COLLECTIBLES} className="small-box-footer ">
                <i className="fa fa-arrow-circle-right"></i>{" "}
                <span className="active">View Details</span>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default injectModels(["application",'admin'])(Dashboard);
