import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

const Login = (props) => {

  const history = useHistory();

  const handleWallet = (e) => {
    e.preventDefault();
    // Asking if metamask is already present or not
    if (window.ethereum) {
      props.application.setLoading(true);
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (res) => {
          const isAllowed = await isSignerAllowed(res[0]);
          if (isAllowed) {
            props.application.setLoading(false);
            history.push("/dashboard");
          } else {
            props.application.setLoading(false);
            toast.error("Only Owner/Verifier Address can connect!! so disconnect current address", {
              position: toast.POSITION.TOP_CENTER,
            });
            
          }
        });
    } else {
     connect();
     
    }
  };

  const updateDimensions = () => {
    if (window.innerWidth < 768) {
      var element = document.getElementsByClassName(
        "walletconnect-modal__mobile__toggle"
      );

      if (element.length > 0) {
        element[0].lastChild.click();
      }
    }
  };

  const connect = async () => { 
    setTimeout(updateDimensions, 100);
    try {
      const paramWC = {
        rpc: {
          [process.env
            .REACT_APP_BLOCKCHAIN_ID]: `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`,
        },
        mobileLinks: [],
      };
      const provider = new WalletConnectProvider(paramWC);

      const response = await provider.enable();

      localStorage.setItem(
        "wallet_type",
        `${process.env.REACT_APP_WALLET_TYPE_WALLET_CONNECT}`
      );
      localStorage.setItem("wallet", response[0]);
      const isAllowed = await isSignerAllowed(response[0]);
      if (isAllowed) {
        props.application.setLoading(false);
        history.push("/dashboard");
      } else {
        props.application.setLoading(false);
        toast.error("Only Owner/Verifier Address can connect!! so disconnect current address", {
          position: toast.POSITION.TOP_CENTER,
        });
        
      }
      
    } catch (e) {
      console.log(e);
      // props.application.setLoading(false);
      // toast.error("Only Owner/Verifier Address can connect!! so disconnect current address", {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    }
  };

  const isSignerAllowed = async (signerAddress) => {
    try {
      const ownerAdress = await props.admin.getOwnerAddress();
      const verifiersList = await props.admin.getVerifiersAddress();
      if (signerAddress.toUpperCase() === ownerAdress.toUpperCase()) {
        localStorage.setItem("isSignerAllowed", true);
        return true;
      } else {
        for (let i = 0; i < verifiersList.length; i++) {
          if (signerAddress.toUpperCase() === verifiersList[i].toUpperCase()) {
            localStorage.setItem("isSignerAllowed", true);
            return true;
          }
        }
      }

      props.application.setLoading(false);

      return false;
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  return (
    <div>
      <section className="contact-us3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-logo">
                <a href="/">
                  {" "}
                  <img src="/assets/img/signup-logo.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container contact-bg-color login-content">
          <div className="row  ">
            <div className="col-md-6 ">
              <div className="img-select3">
                <img src="/assets/img/wallet-img-new.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 hello">
              <div
                className="contact-form-content1"
                style={{ background: "none", boxShadow: "none" }}
              > 
              <div className="new-user">
               <img src="/assets/img/user-admin.png" alt="" /></div>
                <div className="signup-logo1">
                  
                  <button type="sumbit" onClick={handleWallet}> Connect Wallet</button>
                  
                </div>

                <div className="connect-wallet-design">
                <p>Connect your wallet to authenticate</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </div>
  );
};

export default injectModels(["admin", "application"])(Login);
