import { ethers } from "ethers";
import Web3 from "web3";
import getWalletProvider from "../../../utils/getWalletProvider";
import * as CONTRACT from "../../../contracts";

const web3Object = new Web3(
  new Web3.providers.HttpProvider(`${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`)
);

const DotBankAddress = process.env.REACT_APP_DOTPAY_ADDRESS;

export const addMerchant =
  (
    walletAddress,
    currentShare,
    sharingCliffValue,
    uid,
    BuisnessName,
    BuisnessCategory
  ) =>
  async (dispatch, getState) => {
    try {
      if (currentShare > sharingCliffValue) {
        console.log("invalid arguments of share");
        return Promise.reject("invalid arguments of share");
      }
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();

        const dotPayContract = new ethers.Contract(
          DotBankAddress,
          CONTRACT.DotPayContract.abi,
          signer
        );

        let transaction = await dotPayContract.addMerchant(
          walletAddress,
          currentShare,
          sharingCliffValue,
          uid,
          BuisnessName,
          BuisnessCategory
        );
        let tx = await transaction.wait();
        let event = tx.events[0];

        return event;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

export const addVerifier =
  (verifierAddress, verifierName, verifierEmail, verifierPhone) => async () => {
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();

        const dotPayContract = new ethers.Contract(
          DotBankAddress,
          CONTRACT.DotPayContract.abi,
          signer
        );

        let transaction = await dotPayContract.addVerifier(
          verifierAddress,
          verifierName,
          verifierEmail,
          verifierPhone
        );
        let tx = await transaction.wait();

        return true;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };
export const removeVerifier = (VerifierAddress) => async () => {
  try {
    const provider = await getWalletProvider();
    if (!provider) {
      throw new Error("Only metamask is supported");
    } else {
      const signer = provider.getSigner();
      console.log(signer, "signer");

      const dotPayContract = new ethers.Contract(
        DotBankAddress,
        CONTRACT.DotPayContract.abi,
        signer
      );
      console.log(dotPayContract);
      let transaction = await dotPayContract.removeVerifier(VerifierAddress);
      let tx = await transaction.wait();
      console.log(tx, "check");
      return true;
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const isMerchantBlocked =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      merchantContractAddress = merchantContractAddress.substr(
        merchantContractAddress.length - 40
      );
      merchantContractAddress = "0x" + merchantContractAddress;
      const currentStatus = await dotPayContract.methods
        .getMerchantDetails(merchantContractAddress)
        .call();

      return currentStatus.isMerchantBlocked;
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

export const blockMerchant =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();

        const dotPayContract = new ethers.Contract(
          DotBankAddress,
          CONTRACT.DotPayContract.abi,
          signer
        );
        merchantContractAddress = merchantContractAddress.substr(
          merchantContractAddress.length - 40
        );
        merchantContractAddress = "0x" + merchantContractAddress;

        let transaction = await dotPayContract.blockMerchant(
          merchantContractAddress
        );

        return transaction;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

export const unBlockMerchant =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();

        const dotPayContract = new ethers.Contract(
          DotBankAddress,
          CONTRACT.DotPayContract.abi,
          signer
        );
        merchantContractAddress = merchantContractAddress.substr(
          merchantContractAddress.length - 40
        );
        merchantContractAddress = "0x" + merchantContractAddress;

        let transaction = await dotPayContract.unBlockMerchant(
          merchantContractAddress
        );

        return false;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };

export const getMerchantContractAddress =
  (userId) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getMerchantAddress(`${userId}`)
        .call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getMerchantBnbBalance =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        merchantContractAddress
      );
      const transaction = await dotPayContract.methods.getBNBBalance().call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getCustomTokenBalance =
  (tokenAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getTokenBalance(tokenAddress)
        .call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getTokenName = (tokenAddress) => async (dispatch, getState) => {
  try {
    const dotPayContract = new web3Object.eth.Contract(
      CONTRACT.TokenContract.abi,
      tokenAddress
    );
    const transaction = await dotPayContract.methods.name().call();
    const transaction2 = await dotPayContract.methods.symbol().call();

    return transaction + " (" + transaction2 + ")";
  } catch (error) {
    console.log("error", error);

    return null;
  }
};

export const WithdrawToken =
  (tokenAddress, tokenAmount) => async (dispatch, getState) => {
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();

        const dotPayContract = new ethers.Contract(
          DotBankAddress,
          CONTRACT.DotPayContract.abi,
          signer
        );

        let transaction = await dotPayContract.withdrawTokens(
          tokenAddress,
          tokenAmount
        );

        return transaction;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };
export const WithdrawBNB = (tokenAmount) => async (dispatch, getState) => {
  try {
    const provider = await getWalletProvider();
    if (!provider) {
      throw new Error("Only metamask is supported");
    } else {
      const signer = provider.getSigner();
      console.log(signer, "signer");
      const dotPayContract = new ethers.Contract(
        DotBankAddress,
        CONTRACT.DotPayContract.abi,
        signer
      );

      // const dotPayContractCall = new web3Object.eth.Contract(
      //   CONTRACT.DotPayContract.abi,
      //   DotBankAddress
      // );
      // const bankDetails = await dotPayContractCall.methods
      //   .getTheDotPayBankDetails()
      //   .call();

      const transaction = await dotPayContract.withdrawBNB(tokenAmount);

      return transaction;
    }
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const getOwnerAddress =
  () => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getTheDotPayBankDetails()
        .call();

      return transaction[1];
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

  
export const getVerifiersAddress =
() => async (dispatch, getState) => {
  try {
    const dotPayContract = new web3Object.eth.Contract(
      CONTRACT.DotPayContract.abi,
      DotBankAddress
    );
    const transaction = await dotPayContract.methods
      .getVerifiersList()
      .call();

    return transaction;
  } catch (error) {
    console.log("error", error);

    return null;
  }
};

