import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import { Routes } from './constants';
import { DashboardLayout } from './layouts';



const CustomAdminRoute = ({component:Component, ...rest}) => { 
     return (
        <Route {...rest} render={(props)=>{
            // console.log("props",props)
            return (
                <React.Fragment>
                   {/* {<DashboardLayout><Component {...props} /></DashboardLayout>}  */}
                

                 {localStorage.getItem("isSignerAllowed") ===null ? <Redirect to={Routes.LOGIN} /> :<DashboardLayout><Component {...props} /></DashboardLayout>   }
                </React.Fragment>
            )
        }} />
    );
};

export default CustomAdminRoute;