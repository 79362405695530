import React from 'react';
import {Route} from 'react-router-dom';
import { AuthLayout } from './layouts';
import { Redirect } from 'react-router-dom';
import { Routes } from './constants';

const CustomAuthRoute = ({component:Component, ...rest}) => { 

   return (
        <Route {...rest} render={(props)=>{
            return (
                <React.Fragment>
                 {localStorage.getItem("isSignerAllowed") != null ? <Redirect to={Routes.DASHBOARD} /> : <AuthLayout><Component {...props} /></AuthLayout>}
                {/* {<AuthLayout><Component {...props} /></AuthLayout>} */}
                </React.Fragment>
            )
        }} />
    );
};

export default CustomAuthRoute; 