import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Sidebar = () => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  const handleLogOut = () => {
    localStorage.clear();
    toast.success("LogOut SuccessFully !", {
      position: toast.POSITION.TOP_CENTER,
        });
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "" ? "collapsed" : ""
            } `}
            to={Routes.DASHBOARD}
          >
            <i className="fa-solid fa-dashboard" />
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "merchants" ? "collapsed" : ""
            } `}
            to={Routes.MERCHANT}
          >
            <i className="fa-solid fa-users"></i>
            <span>Merchants</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "verify-merchant" ? "collapsed" : ""
            } `}
            to={Routes.VERIFYMERCHANTS}
          >
            <i className="fa-solid fa-user-check"></i>
            <span>Verify Merchants</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "block-merchant" ? "collapsed" : ""
            } `}
            to={Routes.BLOCKMERCHANTS}
          >
            <i className="fa-solid fa-ban"></i>
            <span>Block Merchants</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "verifiers" ? "collapsed" : ""
            } `}
            to={Routes.VERIFIERS}
          >
            <i className="fa-solid fa-person-circle-plus" />
            <span>Verifiers</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link  ${
              splitLocation[1] === "total-collectibles" ? "collapsed" : ""
            } `}
            to={Routes.COLLECTIBLES}
          >
            <i className="fa-solid fa-money-check-dollar"></i>
            <span>Total Collectibles</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="#" onClick={handleLogOut}>
            <i className="fa-solid fa-arrow-right-to-bracket" />

            <span>LogOut</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
