import React from 'react'

const AuthLayout = Props => {
  return (
    <div>
     {Props.children}
    </div>
  )
}

export default AuthLayout;