import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  where,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "../../Firebase/config";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import ToggleButton from "react-toggle-button";
import { injectModels } from "../../redux/injectModels";

const VerifierMerchantProfile = (props) => {
  const params = useParams();
  const [verifer, setVerifer] = useState("");
  const [docData, setdocData] = useState({});

  const getMerchantData = async (verifierAddress) => {
    verifierAddress = verifierAddress.substr(2);
    verifierAddress = "0x000000000000000000000000" + verifierAddress;
    const q = query(
      collection(db, "Merchants"),
      where("verifiedBy", "==", verifierAddress.toLowerCase())
    );
    props.application.setLoading(true);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    props.application.setLoading(false);
    setdocData(arr);
  };

  const getData = async () => {
    const docRef = doc(db, "Verifier", params.Wallet);
    props.application.setLoading(true);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      // console.log(data);
      props.application.setLoading(false);
      setVerifer(data);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    getData();
    getMerchantData(params.Wallet);
  }, []);

  return (
    <React.Fragment>
      <div id="user-profile-2" className="user-profile">
        <div className="tabbable">
          <ul className="nav nav-tabs padding-18">
            <li className="active merchantsame-icon">
              <div className="merchant-icon">
                <Link to={routes.VERIFIERS}>
                  <i className="fa-solid fa-arrow-left"></i>
                </Link>
              </div>
              Verifier
            </li>
          </ul>
          <div className="tab-content no-border padding-24">
            <div id="home" className="tab-pane in active">
              <div className="row">
                <div className="col-md-8 ">
                  <h4 className="blue">
                    <span className="middle">Verifiers Details</span>
                  </h4>
                  <div className="profile-user-info">
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Name </div>
                      <div className="profile-info-value">
                        <span>{verifer.verifierName}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> WalletAddress </div>
                      <div className="profile-info-value">
                        <span>{verifer.verifierAddress}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Email </div>
                      <div className="profile-info-value">
                        <span>{verifer.verifierEmail}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Phone </div>
                      <div className="profile-info-value">
                        <span>{verifer.verifierPhone}</span>
                      </div>
                    </div>
                    <div className="hr hr-8 dotted" />
                  </div>
                </div>
                <hr className="mt-3"></hr>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="blue">
                      <span className="middle">
                        Merchants Verified By {verifer.verifierName}
                      </span>
                    </h4>
                    <div>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">UID</th>
                            <th scope="col">UserName</th>
                            <th scope="col">Email </th>
                            <th scope="col">Phone</th>
                            <th scope="col">Name</th>
                            <th scope="col">Verify</th>
                            <th scope="col">Image</th>
                            <th scope="col">Profile</th>
                          </tr>
                        </thead>
                        <tbody>
                          {docData && docData.length > 0 ? (
                            <React.Fragment>
                              {docData.map((item, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>
                                      <b>{item.uid}</b>
                                    </td>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.name}</td>
                                    <td>
                                      <ToggleButton
                                        value={item.isProfileVerified}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={item.image}
                                        alt=""
                                        className="avatar"
                                      />
                                    </td>
                                    <td className="btn-view">
                                      <Link
                                        to={
                                          routes.MERCHANTDETAILS +
                                          "/" +
                                          item.uid
                                        }
                                      >
                                        <i className="fa-regular fa-eye"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </React.Fragment>
                          ) : (
                            <tr>
                              <td colSpan="10 mt-2">No results found!</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="hr hr-8 dotted" />
                  </div>
                </div>
              </div>
              <div className="space-20" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["application"])(VerifierMerchantProfile);
