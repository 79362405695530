import React, { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const TransactionPage = (props) => {
  const [balance, setBalance] = useState("");
  const [error, setError] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const [showModal, setShow] = useState(false);
  const [customTokenBalance, setCustomTokenBalance] = useState("");
  const [ownerAdress, setOwnerAdress] = useState("");

  const walletType = localStorage.getItem("wallet_type");
  const walletAddress = localStorage.getItem("wallet");

  const getContractAddress = async () => {
    return "0x14D231D3b8faB41091848DD4342E7c603C571acC";
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTokenBalance = async (e) => {
    e.preventDefault();
    let valid = true;
    if (tokenAddress === "") {
      toast.error("Form is not Valid", {
        position: toast.POSITION.TOP_CENTER,
      });
      // setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }

    if (!valid) {
      // setError("Form is not Valid");
      // toast.error("Form is not Valid", {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      // return;
    } else {
      setError("");
      try {
        props.application.setLoading(true);
        const transaction = await props.admin.getCustomTokenBalance(
          tokenAddress
        );
        const tokenTransaction = await props.admin.getTokenName(tokenAddress);
        const tokenBalance = transaction;
        setCustomTokenBalance(transaction);
        setTokenBalance(tokenBalance + " " + tokenTransaction);

        props.application.setLoading(false);
      } catch (err) {
        props.application.setLoading(false);
        console.log(err);
        return Promise.reject(err);
      }
    }
  };

  const handleAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      // setError("You need to Token Address");
      toast.error("You need to Token Address", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setError("");
    }
    setTokenAddress(val.trim());
  };

  const getBnbBalance = async () => {
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        if (address === "0x0000000000000000000000000000000000000000") {
          setBalance("0");
          props.application.setLoading(false);
        } else {
          props.application.setLoading(true);
          const transaction = await props.admin.getMerchantBnbBalance(address);
          setBalance(transaction);
          props.application.setLoading(false);
        }
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const getOwnerAddress = async () => {
    try {
      props.application.setLoading(true);

      const ownerAdress = await props.admin.getOwnerAddress();
      setOwnerAdress(ownerAdress);
      props.application.setLoading(false);
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleTokenWithdraw = async () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (walletAddress.toUpperCase() !== ownerAdress.toUpperCase()) {
      toast.error("Only owner can withdraw", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if(tokenBalance == 0){
      toast.error("balance are low", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.admin.WithdrawToken(
          tokenAddress,
          customTokenBalance
        );
        props.application.setLoading(false);
        return transaction;
      }
    } catch (err) {
      if (err.code === "INVALID_ARGUMENT") {
        toast.error("First get the balance", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (err.code === "ACTION_REJECTED") {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      props.application.setLoading(false);
      console.log(err.code);

      return Promise.reject(err);
    }
  };

  const handleWithdrawBNB = async () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if(balance == 0){
      toast.error("balance are low", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
     if (walletAddress.toUpperCase() !== ownerAdress.toUpperCase()) {
      toast.error("Only owner can withdraw", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.admin.WithdrawBNB(balance);
        props.application.setLoading(false);
        return transaction;
      }
    } catch (err) {
      if (err.code === 4001) {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (err.code === "ACTION_REJECTED") {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    

      else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      props.application.setLoading(false);
      console.log(err.code);

      return Promise.reject(err);
    }
  };

  useEffect(() => {
    getBnbBalance();
    getOwnerAddress();
  }, [balance]);

  return (
    <React.Fragment>
      <section>
        {/* <div>Total BNB:{balance/1e18}</div>
        <button onClick={handleShow}>Check Token Balance</button>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Check Token Balance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please Enter Your Token Address
            <form>
              <div className="searchformfld">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Address"
                  onChange={handleAddress}
                />
                <Button onClick={getTokenBalance}>Get Balance</Button>
              </div>
            </form>
            <div>Token Balance:{tokenBalance}</div>
            <Button onClick={handleTokenWithdraw}>Withdraw</Button>
          </Modal.Body>
          {error && (
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          )}
        </Modal>
        <button onClick={handleWithdrawBNB}>Withdraw</button> */}

        <div className="bnb-btn">
          <div className="total-bnb">
            <h6>
              <span>Total BNB :</span>
              {balance}
            </h6>
          </div>

          <button onClick={handleShow}>Check Token Balance</button>
        </div>
        <button onClick={handleWithdrawBNB} className="token">
          Withdraw BNB
        </button>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Check Token Balance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please Enter Your Token Address
            <form>
              <div className="searchformfld">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Address"
                  onChange={handleAddress}
                  
                />
                <Button onClick={getTokenBalance}>Get Balance</Button>
              </div>
            </form>
            <div>Token Balance:{tokenBalance}</div>
            <Button onClick={handleTokenWithdraw}>Withdraw</Button>
          </Modal.Body>
          {error && (
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          )}
        </Modal>
        <ToastContainer />
      </section>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(TransactionPage);
