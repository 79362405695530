import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { injectModels } from "../../redux/injectModels";
import emailjs from "@emailjs/browser";

const MerchantDetail = (props) => {
  const params = useParams();
  const [kycInfo, setkycInfo] = useState([]);
  const [docData, setdocData] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const walletType = localStorage.getItem("wallet_type");
  const walletAddress = localStorage.getItem("wallet");

  const history = useHistory();

  const sendEmail = async (name,email,message) => {
  
    emailjs.init("Fg-H3qIdxTT0CcngL");
        var templateParams = {
          user_name: name,
          message: message,
          user_email:email
      };
   
      emailjs.send('service_bkhrdxj', 'template_x1syhkd', templateParams)
          .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
          }, function(error) {
            console.log('FAILED...', error);
          });
  };

  const getKycData = async () => {
    const docRef = doc(db, "Kyc", params.userId);
    props.application.setLoading(true);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      props.application.setLoading(false);
      setkycInfo(data);
    } else {
      console.log("No such document!");
    }
  };

  const getData = async () => {
    const docRef = doc(db, "Merchants", params.userId);
    props.application.setLoading(true);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      // console.log(data);
      props.application.setLoading(false);
      setdocData(data);
    } else {
      console.log("No such document!");
    }
  };

  const checkBlockStatus = async () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const isMerchantBlocked = await props.admin.isMerchantBlocked(
      docData.merchantContractAddress
    );
    const status = isMerchantBlocked ? "Blocked" : "Unblocked";
    const antistatus = isMerchantBlocked ? "Unblock" : "Block";
    Swal.fire({
      title: `${docData.name}`,
      text: `${docData.name} is ${status}, you can ${antistatus} by clicking button below.`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${antistatus} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (isMerchantBlocked) {
            try {
              props.application.setLoading(true);
              await props.admin.unBlockMerchant(
                docData.merchantContractAddress
              );
              await updateDoc(doc(db, "Merchants", params.userId), {
                isProfileBlocked: false,
              });
              getData();
              await sendEmail(docData.name,docData.email,"your account has been unblocked ");
              props.application.setLoading(false);
              Swal.fire("unBlocked!", "Merchant is Unblocked .", "success");
            } catch (err) {
              props.application.setLoading(false);
              console.log(err);
              return Promise.reject(err);
            }
            getData();
            Swal.fire("unBlocked!", "Merchant is Unblocked .", "success");
          } else {
            try {
              props.application.setLoading(true);
              await props.admin.blockMerchant(docData.merchantContractAddress);
              await updateDoc(doc(db, "Merchants", params.userId), {
                isProfileBlocked: true,
              });
              getData();
              props.application.setLoading(false);

              Swal.fire("Blocked!", "Merchant is blocked .", "success");
            } catch (err) {
              props.application.setLoading(false);
              if (err.code === 4001) {
                toast.error("user denied the request", {
                  position: toast.POSITION.TOP_CENTER,
                });
            
              } else if (err.code === "ACTION_REJECTED") {
                toast.error("user denied the request", {
                  position: toast.POSITION.TOP_CENTER,
                });
                
              } else if(err.code === 'UNPREDICTABLE_GAS_LIMIT') {
                toast.error("Something  wrong", {
                  position: toast.POSITION.TOP_CENTER,
                });
                
              }
              else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                });
              
              }
              console.log(err);
              return Promise.reject(err);
            }
            getData();
            await sendEmail(docData.name,docData.email,"your account has been blocked due to miscellaneous activity");
            Swal.fire("Blocked!", "Merchant is Blocked .", "success");
          }
        } catch (err) {

          if (err.code === 4001) {
            toast.error("user denied the request", {
              position: toast.POSITION.TOP_CENTER,
            });
        
          } else if (err.code === "ACTION_REJECTED") {
            toast.error("user denied the request", {
              position: toast.POSITION.TOP_CENTER,
            });
            
          } else if(err.code === 'UNPREDICTABLE_GAS_LIMIT') {
            toast.error("Something  wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
            
          }
          else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          
          }
        
          props.application.setLoading(false);
          console.log(err);
          return Promise.reject(err);
        }
      }
    });
    console.log(isMerchantBlocked, "....");
    return isMerchantBlocked;
  };

  // const sendMail = async () => {
  //   await sendEmail();
  // };

  const merchantAddress = () => {
    if (docData.merchantContractAddress) {
      const m = docData.merchantContractAddress.substr(
        docData.merchantContractAddress.length - 40
      );
      return "0x" + m;
    } else {
      return "Please Wait for Verification";
    }
  };

  useEffect(() => {
    getData();
    getKycData();
  }, []);

  const handleVerified = () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "After verified !! merchant access all features.",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Verified it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);
          // add a field to take the below values later
          let event = await props.admin.addMerchant(
            kycInfo.WalletAddress,
            "100000000000000000",
            "5000000000000000000",
            params.userId,
            kycInfo && kycInfo.BillingLabel,
            kycInfo && kycInfo.buisnessCategory
          );

          let merchantContractAddress = event.topics[1];
          let verifierAddress = event.topics[2];
          console.log(merchantContractAddress);
          props.application.setLoading(true);
          await updateDoc(doc(db, "Merchants", params.userId), {
            merchantContractAddress: merchantContractAddress,
            isProfileVerified: true,
            verifiedBy: verifierAddress,
          });

          // sendMail()
         await sendEmail(docData.name,docData.email,"your account has been verified");
          
          getData();

          props.application.setLoading(false);
          Swal.fire("Verified!", "Merchant is Verified .", "success");
        } catch (err) {
          props.application.setLoading(false);
          if (err.code === 4001) {
            toast.error("user denied the request", {
              position: toast.POSITION.TOP_CENTER,
            });
        
          } else if (err.code === "ACTION_REJECTED") {
            toast.error("user denied the request", {
              position: toast.POSITION.TOP_CENTER,
            });
            
          } else if(err.code === 'UNPREDICTABLE_GAS_LIMIT') {
            toast.error("Something  wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
            
          }
          else {
            toast.error("Something went wrong", {
              position: toast.POSITION.TOP_CENTER,
            });
          
          }
          console.log(err);
          return Promise.reject(err);
        }
      }
    });
  };
  const handleReject = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "After Reject !! merchant can't access all features.",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);
          await updateDoc(doc(db, "Merchants", params.userId), {
            isKycComplete: false,
          });
          getData();
          await sendEmail(docData.name,docData.email,"your account has been rejected, please contact with support team");
          props.application.setLoading(false);
          Swal.fire("Verified!", "Merchant is Rejected .", "success");
        } catch (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
          console.log(err);
          return Promise.reject(err);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div id="user-profile-2" className="user-profile">
        <div className="tabbable">
          <ul className="nav nav-tabs padding-18">
            <li className="active merchantsame-icon">
              <div className="merchant-icon">
                <Link to="#" onClick={() => history.goBack()}>
                  <i className="fa-solid fa-arrow-left"></i>
                </Link>
              </div>
              Merchant Details
            </li>
          </ul>
          <div className="tab-content no-border padding-24">
            <div id="home" className="tab-pane in active">
              <div className="row">
                <div className="col-md-2">
                  <span className="profile-picture">
                    <img
                      className="editable  img-fluid"
                      alt=" Avatar"
                      id="avatar2"
                      // src={docData.image}
                      src={
                        imageLoading
                          ? "/assets/img/loader.svg"
                          : docData.image
                          ? docData.image
                          : "/assets/img/image-placeholder.svg"
                      }
                    />
                    {docData.isProfileVerified ? (
                      <img
                        src="../assets/img/verified.png"
                        alt=""
                        className="verified-img"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </div>

                <div className="col-md-10 ">
                  <div className="merchnat-btn">
                    <h4 className="blue">Personal Details</h4>
                    <div className="merchant-demo">
                      {docData.isProfileVerified ? (
                        ""
                      ) : (
                        <button className="btn-verfy" onClick={handleVerified}>
                          {" "}
                          Verify
                        </button>
                      )}
                      {docData.isProfileVerified ? (
                        ""
                      ) : (
                        <button className="btn-reject" onClick={handleReject}>
                          {" "}
                          Reject
                        </button>
                      )}
                    </div>
                    <div className="button-kyc">
                      {docData.isProfileVerified ? (
                        <button
                          className="block-btn"
                          onClick={checkBlockStatus}
                        >
                          <i className="fa-solid fa-gear"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="profile-user-info">
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Name </div>
                      <div className="profile-info-value">
                        <span>{docData.name}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Username </div>
                      <div className="profile-info-value">
                        <span>{docData.username}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Email </div>
                      <div className="profile-info-value">
                        <span>{docData.email}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> Phone </div>
                      <div className="profile-info-value">
                        <span>{docData.phone}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name"> User Id </div>
                      <div className="profile-info-value">
                        <span>{docData.uid}</span>
                      </div>
                    </div>
                    <div className="profile-info-row">
                      <div className="profile-info-name">
                        {" "}
                        Merchant Contract Address{" "}
                      </div>
                      <div className="profile-info-value">
                        <span>{merchantAddress()}</span>
                      </div>
                    </div>

                    <div className="hr hr-8 dotted" />
                  </div>
                </div>
                <hr className="mt-3"></hr>
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="blue">
                      <span className="middle">Business Details</span>
                    </h4>
                    <div className="profile-user-info">
                      <div className="profile-info-row">
                        <div className="profile-info-name"> Business Type </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.BuisnessType}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">
                          {" "}
                          Business Label{" "}
                        </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.BillingLabel}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">
                          {" "}
                          Business Category{" "}
                        </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.buisnessCategory}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">Site Url </div>
                        <div className="profile-info-value">
                          <span>
                            {kycInfo.SiteUrl
                              ? kycInfo.SiteUrl
                              : "Accept payments on Website"}
                          </span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">
                          {" "}
                          Business Description{" "}
                        </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.Description}</span>
                        </div>
                      </div>
                    </div>
                    <div className="hr hr-8 dotted" />
                  </div>
                  <div className="col-md-6">
                    <h4 className="blue">
                      <span className="middle">KYC Details</span>
                    </h4>
                    <div className="profile-user-info">
                      <div className="profile-info-row">
                        <div className="profile-info-name"> PAN </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.Pan}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">
                          {" "}
                          PAN Holder Name{" "}
                        </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.PanholderName}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name"> Pin Code </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.Pincode}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name"> City </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.City}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name"> State </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.State}</span>
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-info-name">
                          {" "}
                          Wallet Address{" "}
                        </div>
                        <div className="profile-info-value">
                          <span>{kycInfo.WalletAddress}</span>
                        </div>
                      </div>
                    </div>
                    <div className="hr hr-8 dotted" />
                  </div>
                </div>
              </div>
              <div className="space-20" />
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(MerchantDetail);

/* file created by sourav mishra on 22/07/2022 */
