import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";
import {
  collection,
  where,
  getDocs,
  query,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../Firebase/config";
import { routes } from "../../constants/routes";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/;

const sendEmail = async (name,email,message) => {
  
  emailjs.init("Fg-H3qIdxTT0CcngL");
      var templateParams = {
        user_name: name,
        message: message,
        user_email:email
    };
 
    emailjs.send('service_bkhrdxj', 'template_x1syhkd', templateParams)
        .then(function(response) {
          console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
          console.log('FAILED...', error);
        });
};
const Verifiers = (props) => {
  const [verifierAddress, setVerifierAddress] = useState("");
  const [verifierName, setVerifierName] = useState("");
  const [verifierEmail, setverifierEmail] = useState("");
  const [verifierPhone, setVerifierPhone] = useState("");
  const [showModal, setShow] = useState(false);
  const [error, setError] = useState("");
  const [verifer, setVerifer] = useState("");
  const [ ownerAdress,setOwnerAdress]  = useState("");
  const walletType = localStorage.getItem("wallet_type");
  const walletAddress = localStorage.getItem("wallet");

  const getData = async () => {
    const q = query(
      collection(db, "Verifier"),
      where("isEnabeled", "==", true)
    );
    props.application.setLoading(true);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    props.application.setLoading(false);
    setVerifer(arr);
  };

  useEffect(() => {
    getData();
    getOwnerAddress();
  }, []);

  const handleVerifierWalletAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    // const wallet = "0x1D3386566e70EB12268d664a044916C8F42C75b6";
    const st = val.slice(0, 2);
    const lt = "0x";
    if (st === lt) {
      if (e.target.value.length > e.target.maxLength)
        e.target.value = e.target.value.slice(0, e.target.maxLength);

      if (val.trim() === "") {
        setError("You need to add Verifier Wallet Address");
      } else {
        setError("");
      }
      setVerifierAddress(val.trim());
    } else {
      setError("Please enter valid Wallet Address");
    }
  };
  const getOwnerAddress = async () => {
    try {
      props.application.setLoading(true);

      const ownerAdress = await props.admin.getOwnerAddress();
      setOwnerAdress(ownerAdress)
      props.application.setLoading(false);
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleVerifierEmail = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      //validations for email
      setError("Email is required");
    } else if (!emailRegex.test(val)) {
      setError("Enter a valid Email ");
    } else {
      setError("");
    }
    setverifierEmail(val.trim());
  };

  const handleVerifierName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("You need to add Verifier Name");
    } else {
      setError("");
    }
    setVerifierName(val.trim());
  };

  const handleVerifierPhone = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if(val.length===17) return false;
    if (val.trim() === "") {
      setError("You need to add Verifier Phone");
    } else if (!phoneRegex.test(val)) {
      setError("Please Enter a valid Phone");
    } else {
      setError("");
    }
    setVerifierPhone(val.trim());
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!walletType && !walletAddress) {
      alert("Wallet is not connected")
      // alert("Wallet not connected")
      return;
    }
    if (walletAddress.toUpperCase() !== ownerAdress.toUpperCase() ) {
      alert("Only owner can add verifier")
      return;
 }


    let valid = true;
  
    if (verifierAddress === "") {
      setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }
    if (verifierName === "") {
      setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }
    if (verifierEmail === "") {
      setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }
    if (verifierPhone === "") {
      setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }
    if (!valid) {
      setError("Form is not Valid");
      return;
    } else {
      setError("");
      try { 
        props.application.setLoading(true);
        await props.admin.addVerifier(
          verifierAddress,
          verifierName,
          verifierEmail,
          verifierPhone
        );

        await setDoc(doc(db, "Verifier", verifierAddress), {
          verifierAddress: verifierAddress,
          verifierName: verifierName,
          verifierEmail: verifierEmail,
          verifierPhone: verifierPhone,
          isEnabeled: true,
        });
        await sendEmail(verifierName,verifierEmail,"congratulations! you are added as a verifier");
        props.application.setLoading(false);
        toast.success("Verifier Added SuccessFully!", {
          position: toast.POSITION.TOP_CENTER,
        });
        handleClose();
    
        getData();
      } catch (err) {
      
        if (err.code === 4001) {
          alert("User denied the request");
        } else if (err.code === "ACTION_REJECTED") {
          alert("User denied the request");
        } else if(err.code === 'UNPREDICTABLE_GAS_LIMIT') {
          alert('Already Added as a verifier')
        }
        else {
          alert("Something went wrong");
        }
        props.application.setLoading(false);
        console.log(err);
        return Promise.reject(err);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="verifier-buttton">
        <Button onClick={handleShow}>Add Verifier</Button>{" "}
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add verifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please Enter the Verifier Wallet Address
          <form>
            <div className="searchformfld">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Wallet Address*"
                onChange={handleVerifierWalletAddress}
                maxLength={42}
                required
              />
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Verifier Name*"
                onChange={handleVerifierName}
                required
              />
              <input
                type="email"
                className="form-control mb-3"
                placeholder="Verifier Email*"
                onChange={handleVerifierEmail}
                required
              />
              <input
                type="number"
                className="form-control mb-3"
                placeholder="Verifier Phone*"
                onChange={handleVerifierPhone}
                value={verifierPhone}
                required
              />
            </div>
          </form>
        </Modal.Body>
        {error && (
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="alert alert-danger">{error}</div>
          </div>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" onClick={handleSubmit}>
           Add Verifier
          </Button>
        </Modal.Footer>
      </Modal>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Verifier Address</th>
            <th scope="col">Verifier Email</th>
            <th scope="col">Verifier Name </th>
            <th scope="col">Verifier Phone</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {verifer && verifer.length > 0 ? (
            <React.Fragment>
              {verifer.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <b>{item.verifierAddress}</b>
                    </td>
                    <td>{item.verifierEmail}</td>
                    <td>{item.verifierName}</td>
                    <td>{item.verifierPhone}</td>
                    <td className="btn-view">
                      <Link
                        to={
                          routes.VERIFIERMERCHANTPROFILE +
                          "/" +
                          item.verifierAddress
                        }
                      >
                        <i className="fa-regular fa-eye"></i>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ) : (
            <tr>
              <td colSpan="10 mt-2">No results found!</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(Verifiers);
