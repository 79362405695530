import store from '../../store';
import { blockMerchant,addMerchant,unBlockMerchant ,isMerchantBlocked,addVerifier,
    removeVerifier,getMerchantContractAddress,getMerchantBnbBalance,getCustomTokenBalance,getTokenName,WithdrawBNB,WithdrawToken,getOwnerAddress,getVerifiersAddress} from './action';

const obj = {

    blockMerchant:(merchantContractAddress)=>store.dispatch(blockMerchant(merchantContractAddress)),
    unBlockMerchant:(merchantContractAddress)=>store.dispatch(unBlockMerchant(merchantContractAddress)),
    isMerchantBlocked:(merchantContractAddress)=>store.dispatch(isMerchantBlocked(merchantContractAddress)),
    addMerchant:(walletAddress, currentShare, sharingCliffValue,uid,BuisnessName,BuisnessCategory)=>store.dispatch(addMerchant(walletAddress, currentShare, sharingCliffValue,uid,BuisnessName,BuisnessCategory)),
    addVerifier:(verifierAddress,verifierName,verifierEmail,verifierPhone)=>store.dispatch(addVerifier(verifierAddress,verifierName,verifierEmail,verifierPhone)),
    removeVerifier:(VerifierAddress)=>store.dispatch(removeVerifier(VerifierAddress)),
    getMerchantContractAddress:(userId)=>store.dispatch(getMerchantContractAddress(userId)),
    getMerchantBnbBalance:(merchantContractAddress)=>store.dispatch(getMerchantBnbBalance(merchantContractAddress)),
    getCustomTokenBalance:(tokenAddress)=>store.dispatch(getCustomTokenBalance(tokenAddress)),
    getTokenName:(tokenAddress)=>store.dispatch(getTokenName(tokenAddress)),
    WithdrawBNB:(tokenAmount)=>store.dispatch(WithdrawBNB(tokenAmount)),
    WithdrawToken:(tokenAddress,tokenAmount)=>store.dispatch(WithdrawToken(tokenAddress,tokenAmount)),
    getOwnerAddress:()=>store.dispatch(getOwnerAddress()),
    getVerifiersAddress:()=>store.dispatch(getVerifiersAddress()),

}


export default obj;
